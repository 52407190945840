import React from "react"
import Layout from "../../../components/App/Layout"
import Navbar from "../../../components/App/Navbar"
import Footer from "../../../components/App/Footer"
import Mandanten from '../../../components/ProductDetails/netversys/mandanten'
import Seo from "../../../components/App/Seo"

const MandantenPage = () => {
  return (
    <Layout>
      <Seo title={"Perfektes Mandantenmanagement"}
           description={"NETVERSYS ist in der Lage durch sein Mandanten / Tenantsystem" +
           " alle denkbaren Firmenstrukturen abzubilden. Eine Installation - Alle Möglichkeiten."}
           image={"/images/og/netversys-main-og.png"}>
        <script type="application/ld+json">{`
              {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [{
                  "@type": "ListItem",
                  "position": 1,
                  "name": "Home",
                  "item": "https://logentis.de"
                },{
                  "@type": "ListItem",
                  "position": 2,
                  "name": "Versandsoftware",
                  "item": "https://logentis.de/versandsoftware/"
                },{
                  "@type": "ListItem",
                  "position": 3,
                  "name": "Mandanten",
                  "item": "https://logentis.de/versandsoftware/netversys/mandanten/"
                }]
              }
              `}
        </script>
      </Seo>
      <Navbar />
      <Mandanten />
      <Footer />
    </Layout>
  );
}

export default MandantenPage
