import React from "react"
import { Link } from "gatsby"
import mandantenHeader from "../../../assets/images/products/netversys/mandanten_header.svg"
import Sidebar from "./Sidebar"
import { Col, Container, Row } from "react-bootstrap"
import wmMandanten from "../../../assets/images/products/netversys/wm-mandanten.svg"
import WichtigeMerkmale from "../WichtigeMerkmale"
import LexikonBadgesRandomSection from "../../lexikon/LexikonBadgesRandomSection"
import { StaticImage } from "gatsby-plugin-image"

const Mandanten = () => {

  return (
    <>
      <section className="services-details-area ptb-100">
        <Container className="mt-5">
          <Row>
            <Col lg={8} md={12}>
              <div className="services-details-image">
                <img src={mandantenHeader} alt="Mandanten Management" />
              </div>

              <div className="services-details-desc">
                <span className="sub-title">NETVERSYS</span>
                <h1 className={"pt-3"}>Mandanten</h1>
                <p>
                  Das Mandantenmanagement in NETVERSYS bietet die Möglichkeit Abteilungen, Kunden oder Standorte
                  voneinander zu trennen, sodass unterschiedlichste Organisationsformen abgebildet werden können. Durch
                  eine intelligente Unterteilung können Sie die Gruppenstrukturen einer Holding oder ganze Konzerne
                  abbilden. Als Kontraktlogistiker modellieren Sie alle Läger für die Sie verschicken ebenfalls über
                  unsere Mandanten. Die Nutzer, Rollen und Rechte im System sorgen nicht nur für eine schlanke
                  Oberfläche, sondern können für jeden Mandanten gepflegt werden. Dadurch ist NETVERSYS mit einer
                  Installation in der Lage alle denkbaren Organisationsstrukturen abzubilden
                </p>

                <WichtigeMerkmale items={[
                  "Verschiedene Abrechnungsnummern",
                  "Rechte und Rollen pro Mandant",
                  "Übersichtliche Nutzeroberfläche",
                  "Diverse Organisationsstrukturen abbildbar"
                ]} image={<img src={wmMandanten}
                               alt="Mandanten Merkmale"
                               className={"ml-lg-4 ml-0"} />} />

                <h3>Relevantes aus dem Lexikon</h3>

                <LexikonBadgesRandomSection />
              </div>
            </Col>

            <Col lg={4} md={12}>
              <Sidebar />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="services-area pt-4 pb-70 bg-f1f8fb">
        <Container>
          <span className="mb-2 text-primary"><i className={"bx bxs-bullseye"} /> Im Detail</span>
          <h2>Mandanten in NETVERSYS</h2>
          <Row className={"mt-5"}>
            <Col md={6} xs={12}>
              <StaticImage
                src="../../../assets/images/products/netversys/screenshots/nv_client_framed.png"
                alt="Mandanten Management Screenshot"
                placeholder="blurred"
                width={600}
              />
            </Col>
            <Col md={{ offset: 1, span: 5 }} xs={{ offset: 1, span: 11 }}>
              <p>
                Neben anderen Daten wird auch die Carrierzuordnung auf Mandantenebene vorgenommen. Durch diese
                Einstellungsmöglichkeit kann vorgegeben werden, welche Frachtführer einem Mandanten zur Verfügung
                stehen. Im Zusammenspiel mit der regelbasierten Carrierselektion können Sie den Versandprozess so genau
                an Ihre Bedürfnisse anpassen. Zahlreiche weitere Einstellungen pro Mandant erlauben neben der
                Umgestaltung des Layouts auch die Veränderung bestimmter Workflows in NETVERSYS.
              </p>
              <p>
                Mit NETVERSYS ist es somit problemlos möglich große Gruppen- oder Konzernstrukturen abzubilden und mit
                einer einzigen Installation den Betrieb zu gewährleisten. Das dabei das
                <Link to={"/versandsoftware/netversys/benutzer/"}>Rechtemanagement</Link> bis auf den
                Benutzer abbildbar ist versteht sich von selbst.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Mandanten
